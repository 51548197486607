import "./index.scss";

import Img from "gatsby-image";
import { Link } from "gatsby";
import React from "react";
import { kebabCase } from "lodash";

class ProjectDetailsView extends React.Component {
  render() {
    const {
      tags,
      quotes,
      title,
      subtitle,
      date,
      description,
      listViewImage,
      listViewVideo,
      problem,
      solution
    } = this.props;

    console.log("this.props", this.props);

    const tagsHtml = tags ? <ProjectDetailsViewTags tags={tags} /> : null;

    const quotesHtml = quotes ? (
      <ProjectDetailsViewQuotes quotes={quotes} />
    ) : null;

    return (
      <section className=" projectdetails">
        <ProjectDetailsViewHeaderSection
          title={title}
          subtitle={subtitle}
          date={date}
          tags={tags}
        />
        <ProjectDetailsViewFeaturedArtifactSection
          listViewImage={listViewImage}
          listViewVideo={listViewVideo}
        />
        <ProjectDetailsViewBodySection description={description} />

        {quotesHtml}
      </section>
    );
  }
}

class ProjectDetailsViewHeaderSection extends React.Component {
  render() {
    const { title, subtitle, date, tags } = this.props;

    const titleText = title ? title : null;
    const subtitleText = subtitle ? subtitle : null;
    const dateText = date ? date : null;
    const tagsText = tags ? <ProjectDetailsViewTags tags={tags} /> : null;

    return (
      <div className="header has-text-centered section container">
        <div>
          <h2 className="title is-1">{titleText}</h2>
          <div className="subtitle is-3">{subtitleText}</div>
        </div>
        <div className="">
          <div>Development, Design, Art: Jesse Harlin</div>
          <div>Shipped: {dateText}</div>
        </div>
        <div>{tagsText}</div>
      </div>
    );
  }
}

class ProjectDetailsViewFeaturedArtifactSection extends React.Component {
  render() {
    const { listViewImage, listViewVideo } = this.props;
    const featuredImage = listViewImage ? listViewImage : null;
    const featuredVideo = listViewVideo ? listViewVideo : null;
    //maybe change the .container's maxwidth variable from 1344px to 1440px
    //need to have a billboard in front of a video image
    return (
      <div className="section is-paddingless ">
        <div className="container" style={{ maxWidth: "1440px" }}>
          <Img className="image" fluid={listViewImage.childImageSharp.fluid} />
        </div>
      </div>
    );
  }
}

class ProjectDetailsViewBodySection extends React.Component {
  render() {
    const { description } = this.props;
    return (
      <div
        className="has-text-centered section container"
        style={{ border: "1px solid teal" }}
      >
        <div className="columns is-mobile is-centered">
          <div className=" column is-half">{description}</div>
        </div>
      </div>
    );
  }
}

class ProjectDetailsViewTags extends React.Component {
  render() {
    const { tags } = this.props;
    return (
      <div className="tagslist">
        {tags.map(tag => (
          <span key={tag + `tag`}>
            <Link to={`/tags/${kebabCase(tag)}/`}>#{tag} </Link>
          </span>
        ))}
      </div>
    );
  }
}

class ProjectDetailsViewQuotes extends React.Component {
  render() {
    const { quotes } = this.props;
    return (
      <div className="quotes">
        {quotes.map((quote, index) => (
          <blockquote key={`quote` + index}>
            <span className="quote">{quote.quote}</span>
            <footer className="author">- {quote.author}</footer>
          </blockquote>
        ))}
      </div>
    );
  }
}

export default ProjectDetailsView;
