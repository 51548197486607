import Content, { HTMLContent } from "../components/Content";
import { Link, graphql } from "gatsby";

import Helmet from "react-helmet";
import Layout from "../components/Layout";
import ProjectDetailsView from "../components/ProjectDetailsView";
import PropTypes from "prop-types";
import React from "react";
import { kebabCase } from "lodash";

export const ProjectPostTemplate = ({
  post,
  content,
  contentComponent,
  elevatorPitch,
  isFeaturedPiece,
  helmet,
  html,
  tags,
  quotes,
  title,
  subtitle,
  date,
  description,
  problem,
  solution,
  listViewImage,
  listViewVideo
}) => {
  return (
    <div>
      <section className="">
        {helmet || ""}
        <ProjectDetailsView
          content={html}
          contentComponent={HTMLContent}
          date={date}
          description={description}
          subtitle={elevatorPitch}
          helmet={helmet}
          isFeaturedPiece={isFeaturedPiece}
          problem={problem}
          quotes={quotes}
          solution={solution}
          tags={tags}
          title={title}
          listViewImage={listViewImage}
          listViewVideo={listViewVideo}
        />
      </section>
    </div>
  );
};

ProjectPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object
};

const ProjectPost = ({ data }) => {
  const { markdownRemark: post } = data;

  const helmetHtml = (
    <Helmet titleTemplate="%s | Simiancraft">
      <title>{post.frontmatter.title}</title>
      <meta name="description" content={`${post.frontmatter.description}`} />
    </Helmet>
  );

  return (
    <Layout>
      <ProjectPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        date={post.frontmatter.date}
        description={post.frontmatter.description}
        elevatorPitch={post.frontmatter.elevatorPitch}
        helmet={helmetHtml}
        isFeaturedPiece={post.frontmatter.isFeaturedPiece}
        post={post.frontmatter.title}
        problem={post.frontmatter.problem}
        quotes={post.frontmatter.quotes}
        solution={post.frontmatter.solution}
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        listViewImage={post.frontmatter.listViewImage}
        listViewVideo={post.frontmatter.listViewVideo}
      />
    </Layout>
  );
};

ProjectPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object
  })
};

export default ProjectPost;

export const pageQuery = graphql`
  query ProjectPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tags
        isFeaturedPiece
        problem
        solution
        listViewVideo
        listViewImage {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        elevatorPitch
        quotes {
          quote
          author
        }
      }
    }
  }
`;
